import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import MediaQuery from 'react-responsive';
import XDate from 'xdate';
import { PREFERENCES, PERMISSIONS } from 'consts';
import { withStyleSheet } from 'hoc/styles';
import { get, getNativePrice, hasPermission, titleize, toDateTimeString } from 'utils';
import { ActionButton } from 'components/ActionButton';
import { Div } from 'components/Div';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { EventPriceChart } from 'components/EventPriceChart';
import { EventUploadProgress } from 'components/EventUploadProgress';
import { ExternalLink } from 'components/ExternalLink';
import { FeaturePreview } from 'components/FeaturePreview';
import { Hint } from 'components/Hint';
import { Icon } from 'components/Icon';
import { Notice } from 'components/Notice';
import { LoaderLogo } from 'components/LoaderLogo';
import { MultiSelect } from 'components/MultiSelect';
import { Option } from 'components/Option';
import { Text } from 'components/Text';
import { Toggle } from 'components/Toggle';
import { Tooltip } from 'components/Tooltip';
import { WithPermission } from 'components/WithPermission';
import { WithPreference } from 'components/WithPreference';
import { TonalGraph } from 'components/TonalGraph';
import { Attachments } from 'floatingTabs/Event/Attachments';
import { RawHTML } from 'components/RawHTML';
import { Checkbox } from 'components/Checkbox';
import { EventNoTranscript } from '../EventNoTranscript';
import { TranscriptEvent } from '../TranscriptEvent';
import { KeywordSearch } from '../KeywordSearch';
import { PartialTranscript } from '../PartialTranscript';
import { styleSheet } from './stylesheet';

const COLORS = {
    1: '#2C3A47',
    2: '#1411b3',
    3: '#811388',
    4: '#1399a0',
    5: '#ca005e',
    6: '#82589F',
    7: '#F97F51',
    8: '#FD7272',
    9: '#EAB543',
    10: '#BDC581',
    11: '#FC427B',
    12: '#1B9CFC',
    13: '#58B19F'
};
const TRANSLATION_TOGGLE_HEIGHT = 40;
const EVENT_ERROR_NO_ACCESS = 'event_error_no_access';
const MARKUP_TYPES = [
    { label: 'annotations', value: 'annotations' },
    { label: 'highlights', value: 'highlights' },
    { label: 'text sentiment', value: 'sentiment' },
    { label: 'tonal sentiment', value: 'tonalSentiment' }
];

const eventText = 'Price change since the start of the call.';
const dayText = 'Price change since last closing price.';

class EventTranscripts extends PureComponent {
    static propTypes = {
        attachments: PropTypes.arrayOf(PropTypes.any),
        audioCallId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        broadcastUrl: PropTypes.string,
        callDate: PropTypes.string,
        callType: PropTypes.string,
        conferenceNumber: PropTypes.string,
        clearIssues: PropTypes.func.isRequired,
        disableQA: PropTypes.bool.isRequired,
        displayType: PropTypes.string.isRequired,
        events: PropTypes.arrayOf(PropTypes.object),
        expectPublishedTranscript: PropTypes.bool,
        failureCode: PropTypes.string,
        firstTranscriptItemStartMs: PropTypes.number,
        focusMode: PropTypes.bool.isRequired,
        handleRef: PropTypes.func.isRequired,
        hasPrivateRecordingAudio: PropTypes.bool.isRequired,
        hasPublishedTranscript: PropTypes.bool,
        hasSummary: PropTypes.bool.isRequired,
        hasTranslation: PropTypes.bool.isRequired,
        hideTranscriptVersionNotice: PropTypes.func,
        highlightsFilterKey: PropTypes.string.isRequired,
        highlightsSortKey: PropTypes.string.isRequired,
        inProgress: PropTypes.bool,
        isEditing: PropTypes.bool.isRequired,
        isEventOwner: PropTypes.bool,
        isPublic: PropTypes.bool,
        isPrivateRecording: PropTypes.bool.isRequired,
        issueReported: PropTypes.bool.isRequired,
        issues: PropTypes.arrayOf(PropTypes.string).isRequired,
        loading: PropTypes.bool.isRequired,
        loadingAudio: PropTypes.bool,
        lockAudioScroll: PropTypes.bool.isRequired,
        mediaPlayer: PropTypes.objectOf(PropTypes.any),
        markupTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
        onKeywordSearch: PropTypes.func.isRequired,
        onNodeSelect: PropTypes.func.isRequired,
        onSearchClear: PropTypes.func.isRequired,
        onTimeSelect: PropTypes.func.isRequired,
        openSubmitDetails: PropTypes.func.isRequired,
        openUpgradeModal: PropTypes.func.isRequired,
        passedStyles: PropTypes.objectOf(PropTypes.any),
        publishedTranscriptSource: PropTypes.string,
        processingAudio: PropTypes.bool.isRequired,
        replayUrl: PropTypes.string,
        resultsIndex: PropTypes.number.isRequired,
        reverseTranscripts: PropTypes.bool,
        retryConnection: PropTypes.func.isRequired,
        retryTranscription: PropTypes.func.isRequired,
        reportIssue: PropTypes.func.isRequired,
        saveSettings: PropTypes.func.isRequired,
        scrollToBottom: PropTypes.func.isRequired,
        scrollToTop: PropTypes.func.isRequired,
        searchResults: PropTypes.arrayOf(PropTypes.string),
        searchTerm: PropTypes.string.isRequired,
        selectedEvent: PropTypes.objectOf(PropTypes.any),
        selectedNode: PropTypes.string,
        setIssue: PropTypes.func.isRequired,
        setResultsIndex: PropTypes.func.isRequired,
        shareId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        showChart: PropTypes.bool,
        showEstimatedSpeakers: PropTypes.bool.isRequired,
        showHumanEditedOnly: PropTypes.bool.isRequired,
        showTimestamps: PropTypes.bool.isRequired,
        showTranscriptVersionNotice: PropTypes.bool,
        showTranslation: PropTypes.bool.isRequired,
        showSummary: PropTypes.bool.isRequired,
        startAudio: PropTypes.func.isRequired,
        stopAudio: PropTypes.func.isRequired,
        stickyHeight: PropTypes.number.isRequired,
        stickyRef: PropTypes.objectOf(PropTypes.any).isRequired,
        streamId: PropTypes.string,
        streamMatches: PropTypes.arrayOf(PropTypes.any).isRequired,
        styles: PropTypes.objectOf(PropTypes.object).isRequired,
        summaryAudioUrl: PropTypes.string,
        summaryText: PropTypes.arrayOf(PropTypes.any).isRequired,
        summaryTitle: PropTypes.string.isRequired,
        theme: PropTypes.objectOf(PropTypes.object).isRequired,
        toggleAudioScroll: PropTypes.func.isRequired,
        toggleChart: PropTypes.func.isRequired,
        toggleEstimatedSpeakers: PropTypes.func.isRequired,
        toggleFocus: PropTypes.func.isRequired,
        toggleHumanEdited: PropTypes.func.isRequired,
        toggleMarkup: PropTypes.func.isRequired,
        toggleReverse: PropTypes.func.isRequired,
        toggleSummary: PropTypes.func.isRequired,
        toggleTimestamps: PropTypes.func.isRequired,
        toggleTranslation: PropTypes.func.isRequired,
        transcriptionStatus: PropTypes.string,
        uploadPercentComplete: PropTypes.number,
        uploadStatus: PropTypes.string,
        user: PropTypes.objectOf(PropTypes.any),
        userId: PropTypes.string
    };

    static defaultProps = {
        attachments: [],
        audioCallId: null,
        broadcastUrl: null,
        callDate: null,
        callType: 'Unknown',
        conferenceNumber: null,
        events: [],
        expectPublishedTranscript: false,
        failureCode: undefined,
        firstTranscriptItemStartMs: undefined,
        hasPublishedTranscript: false,
        hideTranscriptVersionNotice: null,
        inProgress: false,
        isEventOwner: false,
        isPublic: false,
        loadingAudio: false,
        mediaPlayer: null,
        passedStyles: {},
        publishedTranscriptSource: undefined,
        replayUrl: null,
        reverseTranscripts: false,
        searchResults: null,
        selectedEvent: null,
        selectedNode: null,
        shareId: null,
        showChart: true,
        showTranscriptVersionNotice: true,
        streamId: null,
        summaryAudioUrl: undefined,
        transcriptionStatus: undefined,
        uploadPercentComplete: undefined,
        uploadStatus: undefined,
        user: null,
        userId: undefined
    };

    constructor(props) {
        super(props);
        this.renderPriceChartHeader = this.renderPriceChartHeader.bind(this);
        this.renderRetryConnectionMenu = this.renderRetryConnectionMenu.bind(this);
    }

    realtimeText() {
        const { expectPublishedTranscript } = this.props;
        return `This is a machine generated transcript${
            expectPublishedTranscript ? ', a corrected version will replace it sometime after the event is over.' : '.'
        }`;
    }

    renderRetryConnectionMenu() {
        const {
            hasPublishedTranscript,
            styles,
            isPrivateRecording,
            retryConnection,
            retryTranscription,
            reportIssue,
            setIssue,
            issues
        } = this.props;

        return (
            <Div styles={styles.retryConnectionMenu}>
                {isPrivateRecording && (
                    <Fragment>
                        <Div styles={styles.retryConnectionOption} onClick={retryConnection}>
                            <Div styles={styles.connectionIcon}>
                                <Icon type="connect" color="black" />
                            </Div>
                            <Text size={3}>Retry Event Connection</Text>
                        </Div>
                        <Div styles={styles.retryConnectionOption} onClick={retryTranscription}>
                            <Div styles={styles.connectionIcon}>
                                <Icon type="letters" color="black" />
                            </Div>
                            <Text size={3}>Reconnect Transcription</Text>
                        </Div>
                        <Div styles={styles.retryConnectionOption} onClick={retryConnection}>
                            <Div styles={styles.connectionIcon}>
                                <Icon type="speaker" color="black" />
                            </Div>
                            <Text size={3}>Reconnect Audio</Text>
                        </Div>
                    </Fragment>
                )}
                <Div styles={isPrivateRecording ? styles.retryConnectionOtherPrivate : styles.retryConnectionOther}>
                    <Text size={3} weight="medium">
                        {isPrivateRecording
                            ? 'Other issue'
                            : hasPublishedTranscript
                            ? 'Additional Details'
                            : 'Report issue'}
                    </Text>
                    <Text styles={styles.retryOther} size={1}>
                        {hasPublishedTranscript
                            ? 'What else should we know before we attempt to re-sync the transcript and audio?'
                            : 'Before we attempt to reconnect, let us know what went wrong. This will help us ensure better connections.'}
                    </Text>

                    <Div styles={styles.issueCheckbox} onClick={() => setIssue({ value: 'noTranscript' })}>
                        <Checkbox name="noTranscript" checked={issues.includes('noTranscript')} size={4} />
                        <Text>No Transcription</Text>
                    </Div>
                    <Div styles={styles.issueCheckbox} onClick={() => setIssue({ value: 'badTranscript' })}>
                        <Checkbox name="badTranscript" checked={issues.includes('badTranscript')} size={4} />
                        <Text>Poor Transcription</Text>
                    </Div>
                    <Div styles={styles.issueCheckbox} onClick={() => setIssue({ value: 'transcriptGaps' })}>
                        <Checkbox name="transcriptGaps" checked={issues.includes('transcriptGaps')} size={4} />
                        <Text>Transcription Gaps</Text>
                    </Div>
                    <Div styles={styles.issueCheckbox} onClick={() => setIssue({ value: 'noAudio' })}>
                        <Checkbox name="noAudio" checked={issues.includes('noAudio')} size={4} />
                        <Text>No Audio</Text>
                    </Div>
                    <Div styles={styles.issueCheckbox} onClick={() => setIssue({ value: 'audioCutsOut' })}>
                        <Checkbox name="audioCutsOut" checked={issues.includes('audioCutsOut')} size={4} />
                        <Text>Audio Cuts Out</Text>
                    </Div>
                    <Div styles={styles.issueCheckbox} onClick={() => setIssue({ value: 'badAlignment' })}>
                        <Checkbox name="badAlignment" checked={issues.includes('badAlignment')} size={4} />
                        <Text>Poor Audio Alignment</Text>
                    </Div>
                    {!hasPublishedTranscript && (
                        <Fragment>
                            <Div styles={styles.issueCheckbox} onClick={() => setIssue({ value: 'disconnected' })}>
                                <Checkbox name="disconnected" checked={issues.includes('disconnected')} size={4} />
                                <Text>Disconnected</Text>
                            </Div>
                            <Div styles={styles.issueCheckbox} onClick={() => setIssue({ value: 'notConnected' })}>
                                <Checkbox name="notConnected" checked={issues.includes('notConnected')} size={4} />
                                <Text>Not Connected</Text>
                            </Div>
                        </Fragment>
                    )}
                    <ActionButton
                        disabled={hasPublishedTranscript ? false : !issues.length}
                        styles={styles.retrySubmit}
                        onClick={reportIssue}
                    >
                        <Text weight="medium">
                            {hasPublishedTranscript ? 'Re-Sync Transcript' : 'Report Issue & Retry Connection'}
                        </Text>
                    </ActionButton>
                </Div>
            </Div>
        );
    }

    renderMarkupMenu() {
        const {
            focusMode,
            inProgress,
            lockAudioScroll,
            markupTypes,
            saveSettings,
            styles,
            theme,
            toggleAudioScroll,
            toggleMarkup,
            toggleEstimatedSpeakers,
            toggleHumanEdited,
            toggleTimestamps,
            showTimestamps,
            showEstimatedSpeakers,
            showHumanEditedOnly
        } = this.props;
        const hiddenTypes = MARKUP_TYPES.filter(x => !markupTypes.includes(x.value)).map(({ label }) =>
            titleize(label).toLowerCase()
        );
        const hasHidden = markupTypes.length < MARKUP_TYPES.length;
        const menu = (
            <Div className="tab-tooltip" styles={styles.optionsContainer}>
                <Div styles={{ padding: '12px 14px', flex: 1, borderRight: `1px solid ${theme.colors.gray03}` }}>
                    <Text styles={styles.optionsTitle} size={3} weight="medium">
                        Transcript Settings
                    </Text>
                    <Text size={1} styles={styles.optionsDescription}>
                        Control what features are visible
                    </Text>
                    <Div styles={styles.transcriptSettings}>
                        <Option
                            onClick={toggleAudioScroll}
                            selected={lockAudioScroll}
                            styles={styles.publishedOnlyOption}
                            value="humanEdits"
                            type="checkbox"
                        >
                            <Div>
                                <Text size={1} weight="medium">
                                    Auto Scroll Transcript
                                </Text>
                                <Text
                                    styles={[styles.optionDescription, { lineHeight: '1.2em', marginRight: 6 }]}
                                    size={0}
                                >
                                    When Audio is Playing For Completed Events
                                </Text>
                            </Div>
                        </Option>

                        <WithPermission permissions={PERMISSIONS.featureShowEstimatedSpeakers}>
                            <Option
                                onClick={toggleHumanEdited}
                                selected={showHumanEditedOnly}
                                styles={styles.publishedOnlyOption}
                                value="humanEdits"
                                type="checkbox"
                            >
                                <Div>
                                    <Text size={1} weight="medium">
                                        Only Show Human Edits
                                    </Text>
                                    <Text styles={styles.optionDescription} size={0}>
                                        When editing is in progress
                                    </Text>
                                </Div>
                            </Option>
                            <Option
                                onClick={toggleEstimatedSpeakers}
                                selected={showEstimatedSpeakers}
                                styles={styles.publishedOnlyOption}
                                value="estimatedSpeakers"
                                type="checkbox"
                            >
                                <Div>
                                    <Text size={1} weight="medium">
                                        Show Estimated Speakers
                                    </Text>
                                    <Text styles={styles.optionDescription} size={0}>
                                        As identified by AI models, may have inaccuracies.
                                    </Text>
                                </Div>
                            </Option>
                        </WithPermission>
                        <Option
                            onClick={toggleTimestamps}
                            selected={showTimestamps}
                            styles={styles.publishedOnlyOption}
                            value="timestamps"
                            type="checkbox"
                        >
                            <Div>
                                <Text size={1} weight="medium">
                                    Show Timestamps
                                </Text>
                                <Text styles={styles.optionDescription} size={0}>
                                    In live transcripts
                                </Text>
                            </Div>
                        </Option>
                    </Div>
                </Div>
                <Div styles={{ padding: '12px 14px', flex: 1 }}>
                    <Text styles={styles.optionsTitle} size={3} weight="medium">
                        Markup Preferences
                    </Text>
                    <Text size={1} styles={styles.optionsDescription}>
                        Control what text is highlighted
                    </Text>
                    <MultiSelect
                        multi
                        name="selectMarkupTypes"
                        onChange={toggleMarkup}
                        selected={markupTypes}
                        styles={styles.options}
                    >
                        <Option styles={styles.option} value="annotations" type="checkbox">
                            <Div>
                                <Text size={1} weight="medium">
                                    Annotations
                                </Text>
                                <Text styles={styles.optionDescription} size={0}>
                                    Suggested key terms
                                </Text>
                            </Div>
                        </Option>
                        <Option
                            preferences={{ ...PREFERENCES.eventTranscriptsOnly, defaultValue: false, value: false }}
                            styles={styles.option}
                            type="checkbox"
                            value="highlights"
                        >
                            <Div>
                                <Text size={1} weight="medium">
                                    Highlights
                                </Text>
                                <Text styles={styles.optionDescription} size={0}>
                                    You & your team&apos;s highlights
                                </Text>
                            </Div>
                        </Option>
                        <Option
                            lockedPermission={PERMISSIONS.unlockedSentiment}
                            lockedModalId="sentiment"
                            styles={styles.option}
                            value="sentiment"
                            type="checkbox"
                        >
                            <Div>
                                <Text size={1} weight="medium">
                                    Text Sentiment
                                </Text>
                                <Text styles={styles.optionDescription} size={0}>
                                    Positive & negative text color
                                </Text>
                            </Div>
                        </Option>
                        <Option
                            permission={PERMISSIONS.featureEventsTonalSentiment}
                            styles={styles.option}
                            type="checkbox"
                            value="tonalSentiment"
                        >
                            <Div>
                                <Div styles={styles.markupTonalSentiment}>
                                    <Text size={1} weight="medium">
                                        Tonal Sentiment
                                    </Text>
                                    <FeaturePreview
                                        growUp
                                        styles={styles.featurePreviewMarkup}
                                        xOffset={0}
                                        yOffset={-15}
                                    />
                                </Div>
                                <Text styles={styles.optionDescription} size={0}>
                                    Tonal & textual sentiment scores
                                </Text>
                            </Div>
                        </Option>
                    </MultiSelect>
                </Div>
            </Div>
        );

        return (
            <Tooltip
                isEnabled
                growLeft
                yOffset={-140}
                xOffset={0}
                useElementOffsetRight
                useElementOffsetTop
                persistOnMouseExit
                content={menu}
                hideOnScroll={false}
                blockBackground
                onHide={saveSettings}
            >
                {({ showTooltip }) => (
                    <Hint
                        description={hasHidden ? `${hiddenTypes.join(', ')}` : 'annotations, highlights, sentiment'}
                        fromRight
                        growLeft
                        onClick={showTooltip}
                        styles={styles.toggleMarkup}
                        text={hasHidden ? 'Hiding markup for' : 'Showing markup for'}
                    >
                        <Icon
                            type="gear02"
                            color={
                                markupTypes.length === MARKUP_TYPES.length ? theme.colors.gray04 : theme.colors.orange02
                            }
                        />
                        {!focusMode && !inProgress && <Text size={1}>Settings</Text>}
                    </Hint>
                )}
            </Tooltip>
        );
    }

    renderSearchMarkup() {
        const {
            clearIssues,
            events,
            expectPublishedTranscript,
            focusMode,
            hasPublishedTranscript,
            inProgress,
            isPublic,
            issueReported,
            loadingAudio,
            mediaPlayer,
            onKeywordSearch,
            onSearchClear,
            openUpgradeModal,
            processingAudio,
            resultsIndex,
            reverseTranscripts,
            searchResults,
            searchTerm,
            setResultsIndex,
            showChart,
            startAudio,
            stopAudio,
            styles,
            theme,
            toggleFocus,
            toggleReverse
        } = this.props;
        const hasLive = (events || []).find(e => ['transcript', 'started', 'ended'].includes(e.eventType));
        const playButton = (
            <Div
                data-tname="play-event-audio-event-title"
                styles={styles.playButton}
                onClick={processingAudio ? undefined : !loadingAudio ? startAudio : undefined}
            >
                <Icon type={processingAudio ? 'hourglass' : 'play'} color={theme.colors.white01} />
                <Text size={1} weight="medium">
                    {mediaPlayer.status === 'loading' || loadingAudio
                        ? 'Loading...'
                        : processingAudio
                        ? 'Processing...'
                        : 'Listen Now'}
                </Text>
            </Div>
        );
        return (
            <Div
                styles={{
                    ...styles.searchContainer,
                    padding: focusMode ? '0 10px 1px' : '0 20px 1px',
                    borderTop: !showChart ? `1px solid ${theme.colors.gray01}` : 'none'
                }}
                className="print-hide"
            >
                <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                    {m =>
                        !m && mediaPlayer.canListen ? (
                            <WithPreference {...PREFERENCES.eventTranscriptsOnly} value={false} defaultValue={false}>
                                {mediaPlayer.listening ? (
                                    <Div
                                        data-tname="play-event-audio-event-title"
                                        styles={styles.playButton}
                                        onClick={stopAudio}
                                    >
                                        <Icon type="pause" color={theme.colors.white01} />
                                        <Text size={1} weight="medium">
                                            Pause Audio
                                        </Text>
                                    </Div>
                                ) : mediaPlayer.isLive ? (
                                    <WithPermission permissions={PERMISSIONS.unlockedLiveEvents}>
                                        {({ restricted, isLoading }) =>
                                            (restricted && !isPublic) || isLoading ? (
                                                <Div
                                                    data-tname="upgrade-event-audio-event-title"
                                                    styles={styles.playButton}
                                                    onClick={isLoading ? undefined : openUpgradeModal}
                                                >
                                                    <Icon type="lock02" color={theme.colors.white01} />
                                                    <Text size={1} weight="medium">
                                                        Listen Now
                                                    </Text>
                                                </Div>
                                            ) : (
                                                playButton
                                            )
                                        }
                                    </WithPermission>
                                ) : (
                                    playButton
                                )}
                            </WithPreference>
                        ) : null
                    }
                </MediaQuery>
                {(hasLive || hasPublishedTranscript) && !focusMode && (
                    <Div
                        styles={[
                            styles.transcriptionVersion,
                            hasPublishedTranscript ? styles.publishedVersion : styles.liveVersion
                        ]}
                    >
                        {hasPublishedTranscript ? (
                            <Hint
                                xOffset={-10}
                                yOffset={16}
                                text="Published transcript"
                                description="This is a human edited published version of the transcript."
                            >
                                <Text size={1} weight="medium">
                                    Published
                                </Text>
                            </Hint>
                        ) : (
                            <Hint
                                width={236}
                                xOffset={-10}
                                yOffset={16}
                                text="Realtime transcript"
                                description={this.realtimeText()}
                            >
                                <Text size={1} weight="medium">
                                    Realtime{expectPublishedTranscript && '*'}
                                </Text>
                            </Hint>
                        )}
                    </Div>
                )}
                <WithPreference {...PREFERENCES.eventTranscriptsOnly} value={true} defaultValue={false}>
                    {hasPref => (
                        <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                            {m => (
                                <Fragment>
                                    {(m || focusMode || hasPref) && (
                                        <KeywordSearch
                                            onSearch={onKeywordSearch}
                                            onSearchClear={onSearchClear}
                                            searchTerm={searchTerm}
                                            searchResults={searchResults}
                                            resultsIndex={resultsIndex}
                                            setResultsIndex={setResultsIndex}
                                            disabled={events.length === 0}
                                        />
                                    )}
                                    {!focusMode && !hasPref && !m && <Div styles={styles.spacer} />}
                                </Fragment>
                            )}
                        </MediaQuery>
                    )}
                </WithPreference>
                <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                    {m =>
                        !m && (
                            <WithPreference {...PREFERENCES.eventTranscriptsOnly} value={false} defaultValue={false}>
                                <Fragment>
                                    {inProgress || (hasLive && !hasPublishedTranscript) ? (
                                        <Tooltip
                                            isEnabled={!issueReported}
                                            yOffset={8}
                                            xOffset={0}
                                            useElementOffsetLeft
                                            useElementOffsetBottom
                                            useOutsideClickHandler
                                            persistOnMouseExit
                                            blockBackground
                                            cancelClassName="tab-tooltip"
                                            hideOnScroll={false}
                                            content={this.renderRetryConnectionMenu}
                                            onHide={clearIssues}
                                        >
                                            {({ showTooltip }) => (
                                                <Hint
                                                    onClick={showTooltip}
                                                    text={
                                                        issueReported
                                                            ? 'Attempting Resolution'
                                                            : 'Transcription or Audio Issues?'
                                                    }
                                                    description={
                                                        issueReported
                                                            ? 'Thank you for reporting.'
                                                            : 'Try forcing a reconnection.'
                                                    }
                                                    styles={{
                                                        ...styles.retryBtn,
                                                        cursor: issueReported ? 'not-allowed' : 'pointer',
                                                        marginLeft: focusMode ? 8 : undefined
                                                    }}
                                                >
                                                    {!focusMode && <Icon type="refresh" color={theme.colors.white01} />}
                                                    <Text weight="medium">
                                                        {issueReported ? 'Issue Reported' : 'Fix Connection'}
                                                    </Text>
                                                </Hint>
                                            )}
                                        </Tooltip>
                                    ) : hasPublishedTranscript && mediaPlayer.canListen ? (
                                        <Tooltip
                                            isEnabled={!issueReported}
                                            yOffset={8}
                                            xOffset={0}
                                            useElementOffsetLeft
                                            useElementOffsetBottom
                                            useOutsideClickHandler
                                            persistOnMouseExit
                                            blockBackground
                                            cancelClassName="tab-tooltip"
                                            hideOnScroll={false}
                                            onHide={clearIssues}
                                            content={this.renderRetryConnectionMenu}
                                        >
                                            {({ showTooltip }) => (
                                                <Hint
                                                    onClick={showTooltip}
                                                    text={
                                                        issueReported
                                                            ? 'Attempting Resolution'
                                                            : 'Transcript Out of Sync With Audio?'
                                                    }
                                                    description={
                                                        issueReported
                                                            ? 'Thank you for reporting.'
                                                            : 'Re-run our sync process.'
                                                    }
                                                    styles={{
                                                        ...styles.retryBtn,
                                                        cursor: issueReported ? 'not-allowed' : 'pointer',
                                                        width: focusMode ? 80 : 'auto',
                                                        marginLeft: focusMode ? 8 : undefined
                                                    }}
                                                >
                                                    <Icon type="edit" color={theme.colors.white01} />
                                                    <Text weight="medium">
                                                        {issueReported ? 'Issue Reported' : 'Audio Sync'}
                                                    </Text>
                                                </Hint>
                                            )}
                                        </Tooltip>
                                    ) : null}
                                    <Hint
                                        description={`Click to ${
                                            focusMode ? 'show' : 'hide'
                                        } the chart, content tabs, and sidebar`}
                                        width={160}
                                        text={`Focus mode ${focusMode ? 'on' : 'off'}`}
                                        xOffset={10}
                                    >
                                        <Toggle
                                            gray
                                            responsive={theme.breakpoints.internal.tablet}
                                            styles={focusMode ? styles.focusButtonOn : styles.focusButton}
                                            leftLabel={focusMode ? 'Focus on' : 'Focus off'}
                                            on={focusMode}
                                            onClick={toggleFocus}
                                        />
                                    </Hint>
                                </Fragment>
                            </WithPreference>
                        )
                    }
                </MediaQuery>
                {inProgress && (
                    <Hint
                        description={`Click to show the transcript ${
                            reverseTranscripts ? 'chronologically, earliest to latest' : 'in reverse, latest text first'
                        }`}
                        width={140}
                        text={`Showing ${reverseTranscripts ? 'latest' : 'earliest'} first`}
                        xOffset={10}
                    >
                        <Toggle
                            gray
                            responsive={theme.breakpoints.internal.tablet}
                            styles={styles.directionButton}
                            leftIcon="reverse"
                            leftLabel="reverse"
                            on={reverseTranscripts}
                            onClick={toggleReverse}
                        />
                    </Hint>
                )}
                <MediaQuery minWidth={theme.breakpoints.internal.mobileEdge}>
                    <WithPreference {...PREFERENCES.eventMarkupMenu} value={true} defaultValue={true}>
                        {this.renderMarkupMenu()}
                    </WithPreference>
                </MediaQuery>
            </Div>
        );
    }

    renderTranscripts() {
        const {
            audioCallId,
            callType,
            disableQA,
            events: unfilteredEvents,
            firstTranscriptItemStartMs,
            handleRef,
            hasPublishedTranscript,
            hasTranslation,
            hideTranscriptVersionNotice,
            highlightsFilterKey,
            highlightsSortKey,
            isEditing,
            isEventOwner,
            lockAudioScroll,
            mediaPlayer,
            onNodeSelect,
            publishedTranscriptSource,
            resultsIndex,
            reverseTranscripts,
            searchResults,
            searchTerm,
            selectedNode,
            showEstimatedSpeakers,
            showHumanEditedOnly,
            showTimestamps,
            showTranscriptVersionNotice,
            showTranslation,
            stickyHeight,
            streamMatches,
            styles,
            theme,
            toggleHumanEdited,
            toggleTranslation,
            user,
            userId
        } = this.props;
        const renderedEvents = [];
        const speakerColors = {};
        let speakerEvents = [];
        let speakerElement;
        let lastSpeaker;
        let lastTranscriptId;
        let selectedIndex;

        let events = unfilteredEvents.filter(e => get(e, 'status') !== 'deleted');

        if (disableQA) {
            events = events.filter(e => get(e, 'transcriptSection') !== 'q_and_a');
        }

        const mediaPlayerIsLive = get(mediaPlayer, 'isLive', false);
        const mediaPlayerIsListening = get(mediaPlayer, 'listening', false);

        events.forEach((event, idx) => {
            const {
                eventType,
                connectionId = 1,
                endTime,
                startTime,
                startTimestamp,
                sentiment,
                annotated,
                eventId,
                estimatedSpeaker,
                transcript,
                translated,
                speaker: speakerPublished,
                speakerTurn,
                bookmark,
                startMs
            } = event;

            const key = `event-${eventId}`;
            const numSpeakers = Object.keys(speakerColors).length;
            const speakerNum = numSpeakers + 1;
            const bookmarkId = get(bookmark, 'id');
            let speaker = speakerPublished;
            let isEstimated = false;

            // ASSIGN COLOR TO SPEAKER
            if (!speakerColors[connectionId] && callType === 'custom') {
                if (speakerNum < 14) {
                    speakerColors[connectionId] = COLORS[speakerNum];
                } else {
                    speakerColors[connectionId] = COLORS[speakerNum - 14];
                }
            }

            // Assign estimated speaker if has permission and preference
            if (!speaker && showEstimatedSpeakers && hasPermission(user, PERMISSIONS.featureShowEstimatedSpeakers)) {
                speaker = get(estimatedSpeaker, 'speaker');
                isEstimated = true;
            }

            if (speaker) {
                // If We have a new speaker
                if (!lastSpeaker || (lastSpeaker && lastSpeaker.name !== speaker.name)) {
                    lastSpeaker = speaker;
                    // If we have a previous speaker,
                    // group the speaker element, and
                    // everything the speaker said
                    // into a single div
                    if (speakerElement) {
                        const speakerKey = `speaker-${lastSpeaker.name}-${lastTranscriptId}`;
                        renderedEvents.push(
                            <Div key={speakerKey}>
                                {speakerElement}
                                {reverseTranscripts ? speakerEvents.reverse() : speakerEvents}
                            </Div>
                        );
                        speakerEvents = [];
                    }
                    // Speaker sentiment
                    const isSentimentDivergent = get(speakerTurn, 'isSentimentDivergent', false);
                    const textualScore = get(speakerTurn, 'textSentiment.score');
                    const tonalScore = get(speakerTurn, 'tonalSentiment.score');
                    const speakerName = lastSpeaker.name || '';
                    // Default speaker block wrapper and styles
                    let Wrapper = Div;
                    let wrapperProps = {
                        className: 'published-speaker',
                        styles: {
                            ...styles.speakerBlock,
                            top: hasTranslation ? stickyHeight + TRANSLATION_TOGGLE_HEIGHT : stickyHeight
                        }
                    };

                    let tonalSentimentStrength;
                    let textSentimentStrength;

                    // determine strength of sentiment for tonalScore
                    if (Math.abs(tonalScore) >= 0 && Math.abs(tonalScore) <= 1.5) {
                        tonalSentimentStrength = 'Low';
                    } else if (Math.abs(tonalScore) > 1.5 && Math.abs(tonalScore) <= 3.5) {
                        tonalSentimentStrength = 'Medium';
                    } else if (Math.abs(tonalScore) > 3.5) {
                        tonalSentimentStrength = 'High';
                    }

                    // determine strength of sentiment for textScore
                    if (Math.abs(textualScore) >= 0 && Math.abs(textualScore) <= 1.5) {
                        textSentimentStrength = 'Low';
                    } else if (Math.abs(textualScore) > 1.5 && Math.abs(textualScore) <= 3.5) {
                        textSentimentStrength = 'Medium';
                    } else if (Math.abs(textualScore) > 3.5) {
                        textSentimentStrength = 'High';
                    }

                    let sentimentDisplay1;
                    let sentimentDisplay2;

                    if (tonalScore) {
                        sentimentDisplay1 = (
                            <Div styles={styles.sentimentColumnContainer}>
                                <Text styles={styles.sentimentDot} weight="medium">
                                    {' '}
                                    •{' '}
                                </Text>
                                <Text styles={styles.sentimentTitleColumn}> Audio</Text>
                                <TonalGraph
                                    sentimentValue={tonalScore}
                                    sentimentStrength={tonalSentimentStrength}
                                    styles={styles.sentimentColumn}
                                >
                                    {' '}
                                </TonalGraph>
                            </Div>
                        );
                    }

                    if (textualScore) {
                        sentimentDisplay2 = (
                            <Div styles={styles.sentimentColumnContainer}>
                                <Text styles={styles.sentimentDot} weight="medium">
                                    {' '}
                                    •{' '}
                                </Text>
                                <Text styles={styles.sentimentTitleColumn}> Text</Text>
                                <TonalGraph
                                    sentimentValue={textualScore}
                                    sentimentStrength={textSentimentStrength}
                                    styles={styles.sentimentColumn}
                                >
                                    {' '}
                                </TonalGraph>
                            </Div>
                        );
                    }

                    if (isSentimentDivergent) {
                        Wrapper = Hint;
                        wrapperProps = {
                            className: 'published-speaker',
                            growLeft: true,
                            growUp: true,
                            styles: {
                                ...styles.speakerBlock,
                                top: hasTranslation ? stickyHeight + TRANSLATION_TOGGLE_HEIGHT : stickyHeight
                            },
                            text: `Linguistic divergence detected in ${
                                speakerName
                                    ? `${speakerName}'${speakerName.slice(-1) === 's' ? '' : 's'} speech`
                                    : 'speaker'
                            } block`,
                            width: 210,
                            xOffset: 215,
                            yOffset: -9
                        };
                    }

                    // Set the new speaker
                    // if divergent
                    speakerElement = (
                        <Wrapper {...wrapperProps}>
                            {isSentimentDivergent && (
                                <Hint
                                    disabled={!isEstimated}
                                    yOffset={-26}
                                    xOffset={20}
                                    fromLeft
                                    growUp
                                    text="Speaker Identification Estimated by AI"
                                    description="Not guaranteed to be accurate"
                                    styles={styles.speakerInfoLinguisticDivergence}
                                >
                                    <Text styles={styles.speakerName} size={3} weight="medium">
                                        {lastSpeaker.name}
                                    </Text>
                                    {(lastSpeaker.affiliation || lastSpeaker.title) && (
                                        <Text size={1} styles={styles.speakerAffiliation}>
                                            {`${
                                                lastSpeaker.title && lastSpeaker.affiliation
                                                    ? `${lastSpeaker.title}, `
                                                    : lastSpeaker.title
                                            }${lastSpeaker.affiliation || ''}`}
                                        </Text>
                                    )}
                                </Hint>
                            )}

                            {/** if positive */}
                            {!isSentimentDivergent && tonalScore > 0 && (
                                <Hint
                                    disabled={!isEstimated}
                                    yOffset={-26}
                                    xOffset={20}
                                    fromLeft
                                    growUp
                                    styles={styles.speakerInfoPos}
                                >
                                    <Text styles={styles.speakerName} size={3} weight="medium">
                                        {lastSpeaker.name}
                                    </Text>
                                    {(lastSpeaker.affiliation || lastSpeaker.title) && (
                                        <Text size={1} styles={styles.speakerAffiliation}>
                                            {`${
                                                lastSpeaker.title && lastSpeaker.affiliation
                                                    ? `${lastSpeaker.title}, `
                                                    : lastSpeaker.title
                                            }${lastSpeaker.affiliation || ''}`}
                                        </Text>
                                    )}
                                </Hint>
                            )}
                            {/** if negative */}
                            {!isSentimentDivergent && tonalScore < 0 && (
                                <Hint
                                    disabled={!isEstimated}
                                    yOffset={-26}
                                    xOffset={20}
                                    fromLeft
                                    growUp
                                    styles={styles.speakerInfoNeg}
                                >
                                    <Text styles={styles.speakerName} size={3} weight="medium">
                                        {lastSpeaker.name}
                                    </Text>
                                    {(lastSpeaker.affiliation || lastSpeaker.title) && (
                                        <Text size={1} styles={styles.speakerAffiliation}>
                                            {`${
                                                lastSpeaker.title && lastSpeaker.affiliation
                                                    ? `${lastSpeaker.title}, `
                                                    : lastSpeaker.title
                                            }${lastSpeaker.affiliation || ''}`}
                                        </Text>
                                    )}
                                </Hint>
                            )}
                            {/** if neither  */}
                            {!isSentimentDivergent && !tonalScore && (
                                <Hint
                                    disabled={!isEstimated}
                                    yOffset={-26}
                                    xOffset={20}
                                    fromLeft
                                    growUp
                                    styles={styles.speakerInfo}
                                >
                                    <Text styles={styles.speakerName} size={3} weight="medium">
                                        {lastSpeaker.name}
                                    </Text>
                                    {(lastSpeaker.affiliation || lastSpeaker.title) && (
                                        <Text size={1} styles={styles.speakerAffiliation}>
                                            {`${
                                                lastSpeaker.title && lastSpeaker.affiliation
                                                    ? `${lastSpeaker.title}, `
                                                    : lastSpeaker.title
                                            }${lastSpeaker.affiliation || ''}`}
                                        </Text>
                                    )}
                                </Hint>
                            )}

                            <Div styles={styles.speakerFooter}>
                                {startTimestamp && (
                                    <Text size={1} styles={styles.speakerTime}>
                                        {toDateTimeString(startTimestamp, false, false, 'h:mm:ssTT')}{' '}
                                        {/** this is time  */}
                                    </Text>
                                )}
                                {sentimentDisplay1}
                                {sentimentDisplay2}
                            </Div>
                        </Wrapper>
                    );
                }
            }

            if (eventType === 'started' || eventType === 'ended') {
                const action = eventType === 'started' ? 'started' : 'ended';
                const atTime =
                    eventType === 'started'
                        ? toDateTimeString(startTime, false, true)
                        : toDateTimeString(endTime, false, true);
                renderedEvents.push(
                    <Div styles={{ ...styles.transcriptLine, ...styles.transcriptStartEnd }} key={key}>
                        <Text size={3}>
                            The call has {action} at {atTime}.
                        </Text>
                    </Div>
                );
            }

            if (eventType === 'transcript') {
                const color = connectionId && callType === 'custom' ? speakerColors[connectionId] : '';
                if (transcript) {
                    lastTranscriptId = eventId;
                    let streamMatch;
                    const { streamId } = this.props;

                    // Only when this node is selected
                    // grab the streamId, and search the tracked
                    // stream matches for the it. If it is found,
                    // check if there is an index in the URL, and select
                    // that position in the matches. Pass along to
                    // TranscriptEvent to highlight the user stream match.
                    // Takes under 1ms.
                    if (selectedNode === eventId) {
                        selectedIndex = idx;
                        for (let i = 0; i < streamMatches.length; i += 1) {
                            const stream = streamMatches[i];
                            streamMatch = stream.matches.find(
                                m =>
                                    streamId &&
                                    eventId &&
                                    stream.streamId &&
                                    stream.streamId === streamId &&
                                    m.eventId &&
                                    m.eventId.toString() === eventId.toString()
                            );
                            if (streamMatch) {
                                break;
                            }
                        }
                    }
                    // If there is a speakerElement set
                    // group the events for that speaker
                    (speakerElement ? speakerEvents : renderedEvents).push(
                        <TranscriptEvent
                            annotated={annotated}
                            audioCallId={audioCallId}
                            bookmarkId={bookmarkId}
                            callType={callType}
                            color={color}
                            eventId={eventId}
                            // THE REF IS FOR SCROLLING TO THIS ELEMENT
                            handleRef={handleRef}
                            hideTimestamp={hasPublishedTranscript || isEditing}
                            highlightsFilterKey={highlightsFilterKey}
                            highlightsSortKey={highlightsSortKey}
                            isEventOwner={isEventOwner}
                            isPublished={hasPublishedTranscript}
                            isSelected={selectedNode !== null && selectedNode === eventId}
                            lockAudioScroll={lockAudioScroll}
                            canAutoSelect={selectedIndex < idx}
                            audioPlayed={
                                (startMs - (firstTranscriptItemStartMs || 0)) / 1000 <= (mediaPlayer?.currentTime || 0)
                            }
                            key={key}
                            listening={mediaPlayerIsListening}
                            isLive={mediaPlayerIsLive}
                            onNodeSelect={onNodeSelect}
                            resultsIndex={resultsIndex}
                            reverse={reverseTranscripts}
                            searchResults={searchResults}
                            searchTerm={searchTerm}
                            sentiment={sentiment}
                            showTimestamps={showTimestamps}
                            startTimestamp={startTimestamp}
                            streamMatch={streamMatch}
                            styles={styles.transcriptEvent}
                            transcript={showTranslation ? translated : transcript}
                            userId={userId}
                        />
                    );
                }
            }

            // speakerEvents are usually rendered on the next iteration, but if we are
            // at the end and have speakerEvents left we need to render now since there
            // is no next iteration
            if (idx === events.length - 1 && speakerEvents.length) {
                const speakerKey = `speaker-${lastSpeaker.name}-${lastTranscriptId}`;
                renderedEvents.push(
                    <Div key={speakerKey}>
                        {speakerElement}
                        {reverseTranscripts ? speakerEvents.reverse() : speakerEvents}
                    </Div>
                );
                speakerEvents = [];
            }
        });

        // We don't want to show the partial if the user only wants to see
        // human edited text while the transcript is being edited
        // Permission wise, if they have onlyEdited, or onlyImproved
        // we also want to hide the partial...
        if (
            !hasPublishedTranscript &&
            !(isEditing && showHumanEditedOnly) &&
            !hasPermission(user, PERMISSIONS.transcriptsOnlyEdited) &&
            !hasPermission(user, PERMISSIONS.transcriptsOnlyImproved)
        ) {
            renderedEvents.push(
                <PartialTranscript
                    showTimestamps={showTimestamps}
                    key="partial-transcript"
                    lastEvent={(events || []).slice(-1)[0]}
                    lastTranscriptId={lastTranscriptId}
                    styles={styles}
                    audioCallId={audioCallId}
                />
            );
        }

        if (isEditing && showHumanEditedOnly && !hasPublishedTranscript) {
            renderedEvents.push(
                <WithPermission permissions={PERMISSIONS.featureShowEstimatedSpeakers}>
                    <Notice type="info" styles={styles.editInProgress} richContent>
                        <Text size={3}>
                            Human editing in progress...
                            <br />
                            Transcript will continue to be updated...
                        </Text>
                        <ActionButton onClick={toggleHumanEdited} styles={styles.showUneditedButton}>
                            <Text size={1}>Click to show un-edited texts</Text>
                        </ActionButton>
                    </Notice>
                </WithPermission>
            );
        }

        if (reverseTranscripts) {
            renderedEvents.reverse();
        }

        const versionNoticeVisible = showTranscriptVersionNotice && !!events.length && !hasPublishedTranscript;

        return [
            hasTranslation && (
                <Div
                    onClick={toggleTranslation}
                    styles={[
                        styles.translationToggle,
                        {
                            top: stickyHeight
                        }
                    ]}
                >
                    <Div styles={styles.translationIcon}>
                        <Icon type="translation" color={theme.colors.blue08} />
                    </Div>
                    {showTranslation ? (
                        <Text style={styles.translationToggleText}>View transcript in original language</Text>
                    ) : (
                        <Text style={styles.translationToggleText}>View transcript in English</Text>
                    )}
                </Div>
            ),
            versionNoticeVisible && (
                <WithPreference
                    name={PREFERENCES.hideTranscriptVersionNotice.name}
                    defaultValue={false}
                    value={false}
                    key="versionNotice"
                >
                    <Div styles={styles.versionNotice}>
                        <Div styles={styles.versionNoticeIcon}>
                            <Icon type="robot" color="rgb(153,128,54)" />
                        </Div>
                        <Text styles={styles.versionNoticeText} size={2}>
                            {this.realtimeText()}
                        </Text>
                        <ActionButton
                            onClick={e => hideTranscriptVersionNotice(e, true)}
                            styles={styles.hideVersionNoticeButton}
                        >
                            <Text size={1}>Don&apos;t show again</Text>
                        </ActionButton>
                        <Div onClick={hideTranscriptVersionNotice} styles={styles.closeVersionNotice}>
                            <Icon type="xMark02" color="rgb(153,128,54)" />
                        </Div>
                    </Div>
                </WithPreference>
            ),

            ...renderedEvents,
            publishedTranscriptSource === 'spg' && hasPublishedTranscript ? (
                <Div styles={styles.spWatermark}>
                    <ExternalLink href="https://www.aiera.com/partner-copyright">
                        Copyright © 2023, S&P Global Market Intelligence
                        <br />
                        (and its affiliates as applicable). All rights reserved.
                    </ExternalLink>
                </Div>
            ) : null
        ];
    }

    renderWaiting() {
        const { styles } = this.props;
        return (
            <Div styles={styles.waiting}>
                <Text size={3} styles={styles.waitingText}>
                    Transcription will start automatically when the conference speaker begins.
                </Text>
            </Div>
        );
    }

    renderNoTranscriptMessage() {
        const {
            broadcastUrl,
            callDate,
            callType,
            conferenceNumber,
            expectPublishedTranscript,
            failureCode,
            hasPrivateRecordingAudio,
            loading,
            openSubmitDetails,
            replayUrl,
            styles,
            transcriptionStatus,
            uploadPercentComplete,
            uploadStatus
        } = this.props;
        const diffSeconds = callDate ? new XDate().diffSeconds(new XDate(callDate)) : -Infinity;
        // Assume after 2 days, we aren't getting the published (we may, but lets not give the user false
        // expectations) so 86400 seconds in a day, times 2, times -1 since the diff algo is negsative when
        // comparing now to a day in the past.
        const twoDaysAgo = -1 * 86400 * 2;

        let content;
        // We open the tab before the request
        // so loading hasn't even started yet
        // which can confuse this UI. but all
        // events should have a callDate.
        if (loading || !callDate) {
            content = <LoaderLogo height={60} />;
        } else if (diffSeconds < 0 && callType !== 'custom' && transcriptionStatus === 'missed') {
            content = (
                <Fragment>
                    <Text size={3} styles={styles.noTranscriptText}>
                        {conferenceNumber || broadcastUrl
                            ? 'Sorry, we were unable to transcribe this event'
                            : 'Apologies, no connection details were found for this event'}
                        {expectPublishedTranscript && diffSeconds > twoDaysAgo
                            ? ', but we expect to get the published transcript shortly.'
                            : '.'}
                        <br />
                        <br />
                    </Text>
                    {(broadcastUrl || replayUrl) && (
                        <ExternalLink href={replayUrl || broadcastUrl}>Open Broadcast in a New Window</ExternalLink>
                    )}
                </Fragment>
            );
        } else if (diffSeconds < 0 && callType !== 'custom' && (conferenceNumber || broadcastUrl)) {
            content = (
                <Fragment>
                    <Text size={3} styles={styles.noTranscriptText}>
                        We are attempting to connect, <br />
                        this may take up to 3 minutes,
                        <br /> please wait.
                    </Text>
                </Fragment>
            );
        } else if (conferenceNumber || broadcastUrl) {
            content = (
                <Text size={3} styles={styles.noTranscriptText}>
                    {failureCode === EVENT_ERROR_NO_ACCESS ? (
                        'We do not have access to stream this event live.'
                    ) : (
                        <Fragment>
                            The transcript will appear here
                            <br />
                            after the event begins.
                        </Fragment>
                    )}
                    {expectPublishedTranscript && (
                        <Fragment>
                            <br />
                            <br />
                            We expect to get the published transcript for this event sometime after it has ended.
                            <br />
                            <br />
                            <ActionButton onClick={openSubmitDetails} styles={styles.detailsButton}>
                                <Text size={3}>Submit and Escalate Event Changes</Text>
                            </ActionButton>
                        </Fragment>
                    )}
                </Text>
            );
        } else if (hasPrivateRecordingAudio) {
            content = (
                <Fragment>
                    <Text size={3} styles={styles.noTranscriptText}>
                        Uploaded audio is processing.
                        <br />
                        Transcription will appear shortly.
                    </Text>
                    {uploadPercentComplete !== undefined && (
                        <EventUploadProgress
                            percentComplete={uploadPercentComplete}
                            status={uploadStatus}
                            styles={styles.uploadProgress}
                        />
                    )}
                </Fragment>
            );
        } else {
            content = (
                <Fragment>
                    <Text size={3} styles={styles.noTranscriptText}>
                        We will transcribe this event if we receive
                        <br />
                        connection details before the event&apos;s start time.
                        <br />
                        <br />
                    </Text>
                    <ActionButton onClick={openSubmitDetails} styles={styles.detailsButton}>
                        <Text size={3}>Submit event connection details</Text>
                    </ActionButton>
                </Fragment>
            );
        }

        return (
            <Div styles={styles.noTranscript}>
                {diffSeconds > 0 ? <EventNoTranscript>{content}</EventNoTranscript> : content}
            </Div>
        );
    }

    renderPriceChartHeader({
        currency,
        sinceCloseChange,
        sinceCloseChangePercent,
        callChange,
        callChangePercent,
        latestPrice,
        openChartModal,
        realtimePrices
    }) {
        if (get(realtimePrices, 'length', 0) === 0) return null;
        const { toggleChart, showChart } = this.props;

        const { styles, theme } = this.props;
        const upArrow = <Icon type="triangleUp" color={theme.colors.green03} />;
        const downArrow = <Icon type="triangleDown" color={theme.colors.red01} />;
        return (
            <WithPreference {...PREFERENCES.eventTranscriptsOnly} value={true} defaultValue={false}>
                {hasPref => (
                    <Div styles={styles.priceHeaderContainer} className="print-hide" onClick={toggleChart}>
                        <Div styles={styles.priceHeading}>
                            <Div styles={!showChart ? styles.toggleChartHidden : styles.toggleChart}>
                                <Icon type="chevron02" color={theme.colors.black01} />
                            </Div>
                            <Text weight="medium" size={3}>
                                {hasPref
                                    ? 'Price Movement'
                                    : `Last Price ${getNativePrice({ price: latestPrice, currency })}`}
                            </Text>
                            {!hasPref && (
                                <Div
                                    styles={styles.zoomIcon}
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        openChartModal();
                                    }}
                                    className="eventZoomIcon"
                                >
                                    <Icon type="zoomIn" color={theme.colors.black01} />
                                </Div>
                            )}
                        </Div>
                        <Div styles={styles.price}>
                            {hasPref && (
                                <Text size={3} weight="medium">
                                    {getNativePrice({ price: latestPrice, currency })}
                                </Text>
                            )}
                            {latestPrice && (
                                <Div
                                    styles={{
                                        ...styles.priceChangeContainer,
                                        flexDirection: hasPref ? 'column' : 'row'
                                    }}
                                >
                                    <Div styles={styles.priceChange}>
                                        <Hint
                                            yOffset={-20}
                                            width={124}
                                            xOffset={-4}
                                            fromLeft
                                            growUp
                                            description={eventText}
                                        >
                                            <Text size={hasPref ? 1 : 3} weight="medium">
                                                During Call{' '}
                                            </Text>
                                        </Hint>
                                        <Div styles={styles.spacer} />
                                        <Text
                                            size={hasPref ? 1 : 3}
                                            styles={
                                                callChange > 0
                                                    ? styles.textGreen
                                                    : callChange < 0
                                                    ? styles.textRed
                                                    : styles.textGray
                                            }
                                        >
                                            {callChange > 0 && '+'}
                                            {typeof callChange === 'number' ? callChange.toFixed(2) : '-.--'} (
                                            {typeof callChangePercent === 'number'
                                                ? callChangePercent.toFixed(2)
                                                : '-.--'}
                                            %)
                                        </Text>
                                        {callChange > 0 && upArrow}
                                        {callChange < 0 && downArrow}
                                    </Div>
                                    <Div styles={{ ...styles.priceChange, ...styles.priceSinceLastClose }}>
                                        <Hint yOffset={-20} xOffset={-4} growUp description={dayText}>
                                            <Text size={hasPref ? 1 : 3} weight="medium">
                                                Since Last Close{' '}
                                            </Text>
                                        </Hint>
                                        <Div styles={styles.spacer} />
                                        <Text
                                            size={hasPref ? 1 : 3}
                                            styles={
                                                sinceCloseChange > 0
                                                    ? styles.textGreen
                                                    : sinceCloseChange < 0
                                                    ? styles.textRed
                                                    : styles.textGray
                                            }
                                        >
                                            {sinceCloseChange > 0 && '+'}
                                            {typeof sinceCloseChange === 'number'
                                                ? sinceCloseChange.toFixed(2)
                                                : '-.--'}{' '}
                                            (
                                            {typeof sinceCloseChangePercent === 'number'
                                                ? sinceCloseChangePercent.toFixed(2)
                                                : '-.--'}
                                            %)
                                        </Text>
                                        {sinceCloseChange > 0 && upArrow}
                                        {sinceCloseChange < 0 && downArrow}
                                    </Div>
                                </Div>
                            )}
                        </Div>
                    </Div>
                )}
            </WithPreference>
        );
    }

    renderSummary() {
        const {
            audioCallId,
            hasSummary,
            mediaPlayer,
            showSummary,
            styles,
            summaryAudioUrl,
            summaryTitle,
            summaryText,
            stopAudio,
            toggleSummary
        } = this.props;
        const onSummaryAudio = () => {
            const audioEl = document.querySelector(`#summary-audio-${audioCallId}`);
            audioEl.pause();
            mediaPlayer.removeListener('statusChange', onSummaryAudio);
        };
        return (
            hasSummary && (
                <Div styles={styles.summary} className="print-hide">
                    <Hint
                        hideOnScroll
                        text="Machine Generated"
                        description="Summarization may not be perfect."
                        growUp
                        fromLeft
                        yOffset={-34}
                        xOffset={30}
                        styles={styles.summaryHeader}
                        onClick={toggleSummary}
                        name="toggle-transcript-summary"
                    >
                        <Icon
                            type="chevron02"
                            color="black"
                            styles={
                                showSummary
                                    ? { transform: 'rotate(90deg)', transition: 'all 0.2s ease-in-out' }
                                    : { transition: 'all 0.2s ease-in-out' }
                            }
                        />
                        <Div styles={styles.summaryHeaderText}>
                            <Text span size={3} weight="medium" styles={styles.summaryHeaderLabel}>
                                Automated Summary
                            </Text>
                            {!showSummary && (
                                <Text styles={styles.summaryHeaderTitle} span size={3}>
                                    {summaryTitle || summaryText}
                                </Text>
                            )}
                        </Div>
                    </Hint>
                    {showSummary && (
                        <Div styles={styles.summaryText}>
                            {summaryTitle && (
                                <Text size={3} styles={styles.summaryTitle}>
                                    {summaryTitle}
                                </Text>
                            )}
                            {summaryText.map(smry => (
                                <Div styles={styles.summaryParagraph} key={`${smry.slice(0, 10)}`}>
                                    <RawHTML html={smry} />
                                </Div>
                            ))}
                        </Div>
                    )}
                    {showSummary && summaryAudioUrl && (
                        <Div styles={styles.summaryAudio}>
                            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                            <audio
                                data-tname="transcript-summary-audio"
                                id={`summary-audio-${audioCallId}`}
                                controls
                                src={summaryAudioUrl}
                                onLoad={() => {}}
                                onPlay={() => {
                                    stopAudio();
                                    // Need to make sure the status has changed before we add the listener
                                    setTimeout(() => {
                                        mediaPlayer.addListener('statusChange', onSummaryAudio);
                                    }, 100);
                                }}
                                onPause={() => {
                                    mediaPlayer.removeListener('statusChange', onSummaryAudio);
                                }}
                            />
                        </Div>
                    )}
                </Div>
            )
        );
    }

    render() {
        const {
            attachments,
            audioCallId,
            callType,
            events,
            focusMode,
            displayType,
            hasPublishedTranscript,
            inProgress,
            isPublic,
            markupTypes,
            onTimeSelect,
            passedStyles,
            scrollToBottom,
            scrollToTop,
            selectedEvent,
            shareId,
            showChart,
            stickyHeight,
            stickyRef,
            styles,
            theme,
            user
        } = this.props;
        const containerStyle = focusMode ? styles.containerFocus : styles.container;
        const hasLive = (events || []).find(e => ['transcript', 'started', 'ended'].includes(e.eventType));
        let markupStyles = { ...styles.defaultStyles };

        if (markupTypes.includes('annotations')) {
            markupStyles = { ...markupStyles, ...styles.suggestedStyles };
        }
        if (markupTypes.includes('highlights')) {
            markupStyles = { ...markupStyles, ...styles.highlightStyles };
        }
        if (hasPermission(user, PERMISSIONS.unlockedSentiment) && markupTypes.includes('sentiment')) {
            markupStyles = { ...markupStyles, ...styles.sentimentStyles };
        }
        return (
            <Div styles={{ ...containerStyle, ...passedStyles }}>
                {this.renderSummary()}
                <Attachments fromEvent attachments={attachments} />
                <Div styles={markupStyles} className="print-transcript-wrapper">
                    <Div className="event-sticky-container" ref={stickyRef} styles={styles.stickyContainer}>
                        {!focusMode && callType !== 'custom' && displayType !== 'minimal' ? (
                            <ErrorBoundary>
                                <EventPriceChart
                                    eventId={audioCallId}
                                    onTimeSelect={onTimeSelect}
                                    renderChartHeader={this.renderPriceChartHeader}
                                    selectedTime={get(selectedEvent, 'startTimestamp')}
                                    shareId={isPublic ? shareId : undefined}
                                    showChart={showChart}
                                    styles={styles.pricingContainer}
                                />
                            </ErrorBoundary>
                        ) : null}
                        {(inProgress || hasLive || hasPublishedTranscript) &&
                            displayType !== 'minimal' &&
                            this.renderSearchMarkup()}
                    </Div>
                    {inProgress && !hasLive && !hasPublishedTranscript && this.renderWaiting()}
                    {this.renderTranscripts()}
                </Div>
                <WithPreference {...PREFERENCES.eventTranscriptsOnly} value={true} defaultValue={false}>
                    {hasPref => (
                        <Fragment>
                            <MediaQuery maxWidth={theme.breakpoints.internal.mobile}>
                                {m => (
                                    <Hint
                                        text="Scroll to Bottom"
                                        styles={{ ...styles.scrollDown, bottom: hasPref || isPublic || m ? 60 : 0 }}
                                        onClick={scrollToBottom}
                                        yOffset={-26}
                                        xOffset={-8}
                                        fromLeft
                                        growLeft
                                    >
                                        <Icon type="goDown" color={theme.colors.gray01} />
                                    </Hint>
                                )}
                            </MediaQuery>
                            <Hint
                                text="Scroll to Top"
                                styles={{ ...styles.scrollUp, top: hasPref ? stickyHeight : stickyHeight + 40 }}
                                onClick={scrollToTop}
                                yOffset={-19}
                                xOffset={-8}
                                fromLeft
                                growLeft
                            >
                                <Icon type="goUp" color={theme.colors.gray01} />
                            </Hint>
                        </Fragment>
                    )}
                </WithPreference>
                {!inProgress && !hasLive && !hasPublishedTranscript && this.renderNoTranscriptMessage()}
            </Div>
        );
    }
}

export const EventTranscriptsUI = compose(withStyleSheet(styleSheet))(EventTranscripts);
