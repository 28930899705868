import { keyframes } from '@emotion/react';
import { colors } from 'styles/colors';

const wordFader = keyframes`
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
`;
const searchContainer = {
    zIndex: 6,
    position: 'sticky',
    height: 50,
    alignItems: 'center',
    display: 'flex',
    backgroundColor: colors.white01,
    borderBottom: `1px solid ${colors.gray09}`,
    boxShadow: '0 1px 4px 0 rgba(66,65,82,0.06)'
};
const sentimentBarFill = {
    borderRadius: 6,
    height: 7,
    position: 'absolute',
    top: 0
};

export const styleSheet = theme => ({
    container: {
        backgroundColor: theme.colors.white01,
        margin: 0,
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },
    containerFocus: {
        backgroundColor: theme.colors.white01,
        margin: 0,
        flex: 1
    },
    closeVersionNotice: {
        cursor: 'pointer',
        position: 'absolute',
        top: 5,
        right: 10
    },
    defaultStyles: {
        ...theme.content.annotatedStyles,
        '.annotate': {
            cursor: 'pointer',
            ':hover': {
                backgroundColor: theme.colors.gray08,
                color: theme.colors.white01,
                boxShadow: 'unset'
            }
        }
    },
    detailsButton: {
        borderRadius: 6,
        boxShadow: 'unset',
        justifyContent: 'center',
        backgroundColor: theme.colors.blue08,
        ':hover': {
            backgroundColor: theme.colors.blue10
        },
        ':active': {
            backgroundColor: theme.colors.blue11
        },
        height: 40,
        padding: `0 16px`,
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
        cursor: 'pointer',
        p: {
            color: theme.colors.white01,
            marginLeft: 4
        }
    },
    directionButton: {
        marginLeft: theme.margins.margin00
    },
    editInProgress: {
        borderRadius: 6,
        margin: '0 23px 20px',
        display: 'flex',
        '.noticeContent': {
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between'
        }
    },
    estimatedOption: {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.margins.margin00
    },
    featurePreviewMarkup: {
        marginLeft: 8
    },
    focusButton: {
        marginLeft: theme.margins.margin00,
        ':hover': {
            '.toggleLeftLabel': {
                color: theme.colors.gray08
            },
            '.toggleOff': {
                backgroundColor: theme.colors.white02
            }
        }
    },
    focusButtonOn: {
        backgroundColor: theme.colors.red02,
        marginLeft: theme.margins.margin00,
        '.toggleContainer': {
            backgroundColor: theme.colors.red09
        },
        '.toggleLeftLabel': {
            color: theme.colors.white01
        },
        '.toggleOn': {
            backgroundColor: theme.colors.white01
        },
        ':hover': {
            '.toggleLeftLabel': {
                color: theme.colors.gray03
            },
            '.toggleOn': {
                backgroundColor: theme.colors.gray03
            }
        }
    },
    hideVersionNoticeButton: {
        height: 25,
        marginRight: theme.margins.margin01,
        boxShadow: '0 0 0 1px rgb(153,128,54)',
        ':hover': {
            backgroundColor: 'rgb(228,219,187)'
        }
    },
    highlightStyles: {
        ...theme.content.highlightedStyles
    },
    issueCheckbox: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 4,
        cursor: 'pointer'
    },
    liveVersion: {
        color: theme.colors.red01,
        backgroundColor: `rgba(201,37,30,.05)`
    },
    markupTonalSentiment: {
        alignItems: 'center',
        display: 'flex'
    },
    noDetailsText: {
        borderRadius: 5,
        border: `solid 1px ${theme.colors.gray04}`,
        padding: theme.margins.margin00,
        color: theme.colors.black01,
        textAlign: 'center',
        maxWidth: 350,
        lineHeight: '20px'
    },
    noTranscript: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    noTranscriptText: {
        color: theme.colors.gray08,
        textAlign: 'center',
        maxWidth: 350,
        lineHeight: '20px'
    },
    optionDescription: {
        color: theme.colors.gray06,
        marginTop: 2
    },
    options: {
        marginTop: 6,
        '> div': {
            padding: theme.margins.margin00
        }
    },
    optionsContainer: {
        ...theme.content.hoverMenu,
        display: 'flex',
        width: 460,
        backgroundColor: theme.colors.white01,
        border: `1px solid rgba(0, 0, 0, 0.15)`,
        borderBottom: `1px solid rgba(0, 0, 0, 0.25)`
        // ':last-of-type': {
        //     borderBottom: 'unset',
        //     marginBottom: 'unset'
        // }
    },
    optionsDescription: {
        color: theme.colors.gray06,
        lineHeight: '1.2em'
    },
    optionsTitle: {
        marginBottom: 4
    },
    publishedOnlyOption: {
        padding: theme.margins.margin00
    },
    partialTranscript: {
        opacity: 0,
        animation: `${wordFader} 0.7s forwards`
    },
    playButton: {
        ...theme.content.blueButton,
        marginRight: 10,
        width: 110,
        height: 30,
        borderRadius: 6,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 4px 0 2px',
        boxShadow: 'inset 0 1px 0 0 rgba(255, 255, 255, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.2)',
        color: theme.colors.white01,
        svg: {
            marginRight: 6,
            height: 10
        }
    },
    price: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        whiteSpace: 'nowrap',
        p: {
            lineHeight: '16px'
        }
    },
    priceSinceLastClose: {
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            display: 'none'
        }
    },
    priceText: {
        fontSize: 16,
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            fontSize: 14
        }
    },
    priceChange: {
        marginLeft: 18,
        whiteSpace: 'pre-wrap',
        display: 'flex',
        alignItems: 'center',
        svg: {
            marginLeft: 2
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            marginLeft: 0
        }
    },
    priceChangeContainer: {
        display: 'flex'
    },
    priceHeaderContainer: {
        cursor: 'pointer',
        height: 40,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `0 ${theme.margins.margin01}`,
        ':hover': {
            backgroundColor: theme.colors.gray10
        }
    },
    priceHeading: {
        display: 'flex',
        p: {
            cursor: 'pointer'
        }
    },
    toggleChart: {
        display: 'flex',
        alignItems: 'center',
        transition: 'all 0.2s ease-in-out',
        marginRight: 8,
        cursor: 'pointer',
        transform: 'rotate(90deg)'
    },
    toggleChartHidden: {
        display: 'flex',
        alignItems: 'center',
        transition: 'all 0.2s ease-in-out',
        marginRight: 8,
        cursor: 'pointer'
    },
    transcriptSettings: {
        boxShadow: `0 1px 2px 0 ${theme.colors.gray03}`,
        border: `1px solid ${theme.colors.gray01}`,
        borderRadius: 6,
        display: 'flex',
        flexDirection: 'column',
        marginTop: 6,
        alignItems: 'flex-start'
    },
    pricingContainer: {
        '.eventPricingChart': {
            svg: {
                borderBottom: `1px solid ${theme.colors.gray09}`
            }
        }
    },
    publishedTranscriptContainer: {
        padding: `0 ${theme.margins.margin01} ${theme.margins.margin01}`
    },
    publishedTranscriptContainerNoLive: {
        padding: `${theme.margins.margin00} ${theme.margins.margin01} ${theme.margins.margin01}`
    },
    publishedVersion: {
        color: theme.colors.blue04,
        backgroundColor: 'rgba(21,71,160,.10)'
    },
    connectionIcon: {
        width: 20,
        marginRight: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    retryBtn: {
        alignItems: 'center',
        backgroundColor: theme.colors.orange02,
        borderRadius: 6,
        color: theme.colors.white01,
        display: 'flex',
        height: 30,
        justifyContent: 'center',
        padding: '0 12px 0 10px',
        svg: {
            height: 14,
            marginRight: 6
        },
        whiteSpace: 'nowrap',
        width: 'auto',
        ':active': {
            backgroundColor: theme.colors.orange08
        },
        ':hover': {
            backgroundColor: theme.colors.orange05
        }
    },
    retryConnectionMenu: {
        width: 250,
        background: 'white',
        borderRadius: 6,
        overflow: 'hidden',
        boxShadow: '0 10px 10px 0 rgba(0, 0, 0, 0.2)'
    },
    retryConnectionOption: {
        ...theme.content.hoverMenuOption
    },
    retryConnectionOtherPrivate: {
        borderTop: `1px solid ${theme.colors.gray03}`,
        padding: '10px 12px 10px',
        marginTop: 4,
        display: 'flex',
        flexDirection: 'column'
    },
    retryConnectionOther: {
        padding: '10px 12px 10px',
        display: 'flex',
        flexDirection: 'column',
        '.checkbox04': {
            marginRight: 4
        },
        '.checkboxChecked04': {
            marginRight: 4
        }
    },
    retrySubmit: {
        ...theme.content.blueButton,
        color: theme.colors.white01,
        textAlign: 'center',
        borderRadius: 6,
        marginTop: 6,
        boxShadow: 'unset',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    retryOther: {
        marginTop: 6,
        marginBottom: 10,
        lineHeight: '1.2em'
    },
    scrollDown: {
        height: '23px',
        width: '23px',
        position: 'absolute',
        right: 0,
        paddingBottom: '7px',
        paddingRight: '7px',
        cursor: 'pointer',
        zIndex: 5,
        ':hover': {
            'svg g': {
                fill: theme.colors.blue01
            }
        }
    },
    scrollUp: {
        height: '23px',
        width: '23px',
        position: 'absolute',
        top: '199px',
        right: 0,
        paddingTop: '7px',
        paddingRight: '7px',
        cursor: 'pointer',
        zIndex: 5,
        ':hover': {
            'svg g': {
                fill: theme.colors.blue01
            }
        }
    },
    searchContainer: {
        ...searchContainer
    },
    sentimentBar: {
        backgroundColor: theme.colors.gray01,
        borderRadius: 10,
        height: 7,
        position: 'relative',
        width: 30
    },
    sentimentBarContainer: {
        alignItems: 'center',
        display: 'flex',
        marginLeft: theme.margins.margin00
    },
    sentimentBarNeg: {
        ...sentimentBarFill,
        backgroundColor: theme.colors.pink01
    },
    sentimentBarPos: {
        ...sentimentBarFill,
        backgroundColor: theme.colors.green01
    },
    sentimentColumn: {
        lineHeight: '1.1em',
        width: 'flex',
        padding: '0px 0px 0px 1px',
        transform: 'scale(0.65)',
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: '1px'
    },
    sentimentColumnContainer: {
        padding: '0px 3px 0px 3px',
        width: 'flex'
    },
    sentimentDot: {
        alignItems: 'center',
        padding: '4px 3px 0px 3px',
        float: 'left',
        width: 'flex'
    },
    sentimentDivergenceIcon: {
        marginLeft: theme.margins.margin00,
        svg: {
            height: 10,
            width: 10
        }
    },
    sentimentScoreContainer: {
        alignItems: 'center',
        display: 'flex',
        height: 20,
        justifyContent: 'space-between'
    },
    sentimentSeparator: {
        marginLeft: theme.margins.margin00
    },
    sentimentStyles: {
        ...theme.content.sentimentStyles
    },
    sentimentType: {
        color: theme.colors.gray06,
        marginLeft: theme.margins.margin00
    },
    sentimentTitleColumn: {
        lineHeight: '1.1em',
        width: 'flex',
        float: 'left',
        padding: '4px 8px 0px 2px',
        fontSize: theme.fonts.fontSizes.size01,
        color: theme.colors.gray06
    },
    showUneditedButton: {
        ...theme.content.blueButton,
        display: 'inline-flex',
        borderRadius: 6,
        color: theme.colors.white01,
        alignSelf: 'start',
        boxShadow: 'unset',
        marginTop: 6
    },
    sourceContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        paddingTop: theme.margins.margin01,
        paddingBottom: '6px'
    },
    sourceStyle: {
        display: 'inline-flex'
    },
    spacer: {
        flex: 1
    },
    speakerAffiliation: {
        color: theme.colors.gray04,
        marginBottom: 1
    },
    speakerBlock: {
        display: 'inline-flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '5px 8px 5px 0',
        margin: '3px 0 0 0',
        position: 'sticky',
        top: 51,
        zIndex: 1,
        backgroundColor: 'rgba(255, 255, 255, 0.93)',
        borderRadius: '0 0 6px 0'
    },
    speakerFooter: {
        alignItems: 'center',
        display: 'flex'
    },
    speakerInfo: {
        display: 'flex',
        alignItems: 'flex-end',
        padding: '1px 2px 3px 23px',
        borderBottom: `1px solid ${theme.colors.gray05}`,
        flexWrap: 'wrap'
    },
    speakerInfoNeg: {
        display: 'flex',
        alignItems: 'flex-end',
        padding: '1px 2px 3px 23px',
        borderBottom: `2px solid ${theme.colors.red02}`, // this is the underline color
        flexWrap: 'wrap'
    },
    speakerInfoPos: {
        display: 'flex',
        alignItems: 'flex-end',
        padding: '1px 2px 3px 23px',
        borderBottom: `2px solid ${theme.colors.green01}`, // this is the underline color
        flexWrap: 'wrap'
    },
    speakerInfoLinguisticDivergence: {
        borderBottom: `2px solid ${theme.colors.orange03}`,
        display: 'flex',
        alignItems: 'flex-end',
        padding: '1px 2px 3px 23px',
        flexWrap: 'wrap'
    },
    speakerName: {
        marginRight: theme.margins.margin00
    },
    speakerTime: {
        color: theme.colors.gray06,
        letterSpacing: '1px',
        alignSelf: 'flex-start',
        padding: '4px 0 1px 23px'
    },
    speakerTitle: {
        color: theme.colors.gray04,
        letterSpacing: '1px'
    },
    spWatermark: {
        margin: 23,
        textAlign: 'center',
        a: {
            lineHeight: '1.0em',
            color: theme.colors.gray04,
            textDecoration: 'none',
            fontSize: theme.fonts.fontSizes.size01
        }
    },
    stickyContainer: {
        position: 'sticky',
        top: 0,
        zIndex: 7
    },
    suggestedStyles: {
        ...theme.content.suggestedAnnotationStyles
    },
    summary: {
        borderBottom: `1px solid ${colors.gray01}`
    },
    summaryAudio: {
        margin: '0 0 20px 30px'
    },
    summaryHeader: {
        position: 'relative',
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        height: 40,
        padding: '0 20px',
        svg: {
            marginRight: 8
        },
        ':hover': {
            backgroundColor: theme.colors.gray10
        }
    },
    summaryHeaderLabel: {
        display: 'inline-block',
        whiteSpace: 'nowrap'
    },
    summaryHeaderText: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        left: 33,
        right: 20,
        top: 0,
        bottom: 0
    },
    summaryHeaderTitle: {
        color: theme.colors.gray06,
        marginLeft: 6,
        fontStyle: 'italic',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block'
    },
    summaryParagraph: {
        marginBottom: 10,
        fontSize: theme.fonts.fontSizes.size03,
        '.summary_qa_title': {
            color: theme.colors.black01
        }
    },
    summaryTitle: {
        color: theme.colors.black01,
        marginBottom: 10
    },
    summaryText: {
        color: theme.colors.gray06,
        maxWidth: 600,
        lineHeight: '1.3em',
        padding: '0 20px 8px 33px'
    },
    textGray: {
        color: theme.colors.gray04
    },
    textGreen: {
        color: theme.colors.green03
    },
    textRed: {
        color: theme.colors.red01
    },
    timeStamp: {
        padding: '5px 7px',
        letterSpacing: '1px',
        marginTop: '2px'
    },
    timeStampContainer: {
        flexShrink: 0,
        width: '98px'
    },
    toggleMarkup: {
        display: 'flex',
        height: 30,
        borderRadius: 6,
        marginLeft: 10,
        padding: '0 10px',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: theme.colors.white03,
        p: {
            marginLeft: 6
        },
        ':hover': {
            backgroundColor: theme.colors.gray03,
            'svg g': {
                fill: theme.colors.blue08
            }
        }
    },
    transcriptEvent: {
        padding: `5px ${theme.margins.margin02} 5px 15px`
    },
    transcriptionVersion: {
        height: 30,
        borderRadius: 30,
        width: 75,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: theme.margins.margin00
    },
    transcriptLine: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: `${theme.margins.margin00} ${theme.margins.margin02} 5px 15px`,
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            flexDirection: 'column'
        }
    },
    transcriptPublishedText: {
        lineHeight: '1.3em',
        marginBottom: theme.margins.margin01,
        padding: `0 ${theme.margins.margin00}`
    },
    transcriptSection: {
        marginTop: theme.margins.margin02,
        marginBottom: theme.margins.margin00
    },
    transcriptSpeaker: {
        backgroundColor: theme.colors.white01,
        position: 'sticky',
        top: '50px',
        height: '56px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingTop: '4px',
        lineHeight: '1.0em',
        borderRadius: '2px'
    },
    transcriptSpeakerNoLive: {
        backgroundColor: theme.colors.white01,
        position: 'sticky',
        top: '0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: `${theme.margins.margin00} 0`,
        lineHeight: '1.0em',
        borderRadius: '2px'
    },
    transcriptStartEnd: {
        color: theme.colors.gray04,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        paddingBottom: theme.margins.margin01
    },
    transcriptText: {
        lineHeight: '1.3em',
        marginBottom: theme.margins.margin01
    },
    transcriptTextContainer: {
        fontSize: theme.fonts.fontSizes.size03,
        lineHeight: '1.3em',
        padding: '5px 8px'
    },
    translationToggle: {
        cursor: 'pointer',
        position: 'sticky',
        zIndex: 2,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'rgba(236, 245, 255, 1)',
        color: theme.colors.blue08,
        padding: `0 ${theme.margins.margin01}`,
        height: 40,
        ':hover': {
            backgroundColor: '#d2e8ff'
        }
    },
    translationToggleText: {
        fontSize: theme.fonts.fontSizes.size02,
        marginRight: theme.margins.margin00,
        marginLeft: 6,
        display: 'flex',
        alignItems: 'center',
        flex: 1
    },
    translationIcon: {
        marginLeft: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    uploadProgress: {
        margin: '30px 20px',
        width: 350
    },
    versionNotice: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'rgb(251,234,166)',
        color: 'rgb(153,128,54)',
        borderRadius: 4,
        padding: `${theme.margins.margin00} ${theme.margins.margin00}`,
        margin: `12px ${theme.margins.margin01} 2px`
    },
    versionNoticeIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgb(253,243,208)',
        borderRadius: 15,
        height: 30,
        width: 30
    },
    versionNoticeText: {
        marginLeft: theme.margins.margin00,
        marginRight: theme.margins.margin00,
        display: 'flex',
        alignItems: 'center',
        flex: 1
    },
    waiting: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100% - 51px)',
        marginTop: theme.margins.margin00
    },
    waitingText: {
        color: theme.colors.gray04,
        textAlign: 'center',
        maxWidth: 350,
        lineHeight: '20px'
    },
    zoomIcon: {
        cursor: 'pointer',
        marginLeft: theme.margins.margin00,
        svg: {
            width: 14,
            height: 14,
            transform: 'rotate(270deg)'
        },
        ':hover': {
            'svg g': {
                fill: theme.colors.orange01
            }
        },
        [`@media(max-width: ${theme.breakpoints.internal.mobile})`]: {
            display: 'none'
        }
    }
});
